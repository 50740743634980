const protocols = { 80: 'http://', 443: 'https://' };

const selectDomain = (domains) => {
  if (domains.length === 1) return domains[0];
  for (const domain of domains) {
    const parts = domain.split('.');
    if (parts[0] === 'www') return domain;
  }

  return domains[0];
};

const hostToUrl = (host) =>
  new URL(`${protocols[host.port]}${selectDomain(host.domains)}`);

const urlFromHosts = (hosts) => {
  const httpHost = hosts.find((host) => host.port === 80);
  const httpsHost = hosts.find((host) => host.port === 443);

  if (httpHost && httpHost.httpsRedirect && !httpsHost)
    throw new Error('No https host for upgrade');

  if (httpHost && httpsHost) return hostToUrl(httpsHost);
  if (httpsHost) return hostToUrl(httpsHost);
  if (httpHost) return hostToUrl(httpHost);

  throw new Error('No valid host found!');
};

const deploymentUrl = (path = '') => {
  const url = new URL(path, process.env.NEXT_PUBLIC_SELF_URL);

  const isPreview = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview';

  if (isPreview) {
    const base = `${url.protocol}//${process.env.NEXT_PUBLIC_VERCEL_URL}`;
    return new URL(path, base);
  }

  return url;
};

const parseIcsDate = (icsDate) => {
  const year = icsDate.substr(0, 4);
  const month = icsDate.substr(4, 2);
  const day = icsDate.substr(6, 2);

  const hour = icsDate.substr(9, 2);
  const minute = icsDate.substr(11, 2);
  const second = icsDate.substr(13, 2);

  return new Date(Date.UTC(year, month - 1, day, hour, minute, second));
};

const amazonUrlValid = (url) => {
  const expiresSeconds = url.searchParams.get('X-Amz-Expires');
  const createdDate = parseIcsDate(url.searchParams.get('X-Amz-Date'));

  const expirationTime = parseInt(expiresSeconds, 10) * 1000;
  const createdTime = createdDate.getTime();
  const currentTime = Date.now();

  return expirationTime + createdTime > currentTime;
};

export { amazonUrlValid, deploymentUrl, urlFromHosts };
