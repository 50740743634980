'use client';
import React, { createContext, useCallback, useContext, useMemo, useReducer, } from 'react';
const initialState = {};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SUSPENSION_STARTED': {
            return Object.assign(Object.assign({}, state), { [action.data]: 'STARTED' });
        }
        case 'SUSPENSION_FINSHED': {
            return Object.assign(Object.assign({}, state), { [action.data]: 'FINISHED' });
        }
    }
};
const SuspensionsContext = createContext(null);
const SuspensionsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const createSuspension = useCallback((handle) => {
        dispatch({ type: 'SUSPENSION_STARTED', data: handle });
    }, []);
    const removeSuspension = useCallback((handle) => {
        dispatch({ type: 'SUSPENSION_FINSHED', data: handle });
    }, []);
    const blocked = useMemo(() => Object.values(state).length > 0 &&
        Object.values(state).every((state) => state === 'STARTED'), [state]);
    const suspended = useCallback((handle) => state[handle] || null, [state]);
    const suspensions = useMemo(() => ({ createSuspension, removeSuspension }), [createSuspension, removeSuspension]);
    const value = { blocked, suspended, suspensions };
    return (React.createElement(SuspensionsContext.Provider, { value: value }, children));
};
function useSuspensions() {
    const context = useContext(SuspensionsContext);
    if (!context)
        throw new Error('useSuspensions requires a context!');
    return context;
}
export { SuspensionsProvider, useSuspensions };
