'use client';

import React, { useMemo } from 'react';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';

interface Props {
  children: React.JSX.Element;
  className: string;
  pathname: string;
}

const OAuthLink = ({ children, className, pathname }: Props) => {
  const searchParams = useSearchParams();

  const filteredParams = useMemo(() => {
    const cloneSearchParams = new URLSearchParams(searchParams);
    cloneSearchParams.delete('code');
    cloneSearchParams.delete('state');
    return cloneSearchParams;
  }, [searchParams]);

  const query = Object.fromEntries(filteredParams);
  const href = useMemo(() => ({ pathname, query }), [pathname, query]);

  return (
    <Link prefetch={false} href={href} className={className}>
      {children}
    </Link>
  );
};

export default OAuthLink;
