'use client';
import React, { createContext, useCallback, useContext, useMemo, useReducer, } from 'react';
const NotificationsContext = createContext(null);
const initialState = [];
const reducer = (state, action) => {
    switch (action.type) {
        case 'NOTIFICATION_CREATED': {
            return [...state, action.data];
        }
        case 'NOTIFICATION_REMOVED': {
            return state.filter(({ handle }) => handle !== action.data.handle);
        }
    }
};
const NotificationsProvider = ({ children }) => {
    const [notifications, dispatch] = useReducer(reducer, initialState);
    const createNotification = useCallback((type, handle, substitutions = {}, duration = 5000, cb = () => { }) => {
        (async () => {
            const data = { type, handle, duration, substitutions };
            dispatch({ type: 'NOTIFICATION_CREATED', data });
            cb(handle);
        })();
    }, [dispatch]);
    const removeNotification = useCallback((handle, cb = () => { }) => {
        const data = { handle };
        dispatch({ type: 'NOTIFICATION_REMOVED', data });
        cb();
    }, [dispatch]);
    const value = useMemo(() => ({ createNotification, removeNotification, notifications }), [createNotification, removeNotification, notifications]);
    return (React.createElement(NotificationsContext.Provider, { value: value }, children));
};
function useNotifications() {
    const context = useContext(NotificationsContext);
    if (!context)
        throw new Error('useNotifications requires a context!');
    return context;
}
export { NotificationsProvider, useNotifications };
